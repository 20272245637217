import React from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Addfund from "../Component/Addfund";
import Faild from "../Component/Faild";
import FoundTransfer from "../Component/FoundTransfer";
import Friend from "../Component/Friend";
import FriendList from "../Component/FriendList";
import FriendLiReq from "../Component/FriendReq";

import HameHistory from "../Component/HameHistory";
import Home from "../Component/Home";
import KycComplete from "../Component/KycComplete";
import Login from "../Component/Login";
import Login2 from "../Component/Login2";
import Metrics from "../Component/Metrics";
import Notification from "../Component/Notification";
import Onbid from "../Component/Onbid";
import Process from "../Component/Process";
import Profile from "../Component/Profile";
import ReferEran from "../Component/ReferEran";
import Success from "../Component/Success";
import Support from "../Component/Support";
import TopTenPlay from "../Component/TopTenPlay";
import Trasaction from "../Component/Trasaction";
import ViewBeetel from "../Component/ViewBeetel";
import Wallet from "../Component/Wallet";
import WithdrawalReq from "../Component/WithdrawalReq";
import Withdrwal from "../Component/Withdrwal";
import AuthAdmin from "./AuthAdmin";
import Game from "../Component/Game";
import Onbid2 from "../Component/Onbid2";
import Onbidpopular from "../Component/Onbidpopular";
import Rules from "../Component/Rules";
import Addfund2 from "../Component/AddFound2";
import TermsAndConditions from "../Component/TermsAndConditions";
import PrivacyPolicy from "../Component/PrivacyPolicy";

function AppRoutes() {
  return (
    // < Switch >
    //   < Route exact path="/" component={UserTable} />
    //   < Route exact path="/login" component={Login} />
    // </Switch>

    // <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<Home />} /> */}

      <Route path="/login" element={<Login />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/login/:id" element={<Login2 />} />
      {/* <Route path="/login/:id" element={<AuthAdmin cmp={Login2} />} /> */}

      <Route path="/" element={<AuthAdmin cmp={Home} />} />
      <Route path="/profile" element={<AuthAdmin cmp={Profile} />} />
      <Route path="/friend-search" element={<AuthAdmin cmp={FriendList} />} />
      <Route path="/friend-request" element={<AuthAdmin cmp={FriendLiReq} />} />
      <Route path="/friend-list" element={<AuthAdmin cmp={Friend} />} />
      <Route path="/wallet" element={<AuthAdmin cmp={Wallet} />} />
      {/* <Route path="/metrics" element={<AuthAdmin cmp={Metrics} />} /> */}
      <Route path="/refer-earn" element={<AuthAdmin cmp={ReferEran} />} />
      <Route path="/game-history" element={<AuthAdmin cmp={HameHistory} />} />
      <Route path="/Top-10-Player" element={<AuthAdmin cmp={TopTenPlay} />} />
      <Route path="/notification" element={<AuthAdmin cmp={Notification} />} />
      <Route path="/support" element={<AuthAdmin cmp={Support} />} />
      <Route path="/terms-and-conditions" element={<AuthAdmin cmp={TermsAndConditions} />} />
      <Route path="/privacy-policy" element={<AuthAdmin cmp={PrivacyPolicy} />} />
      <Route path="/transaction" element={<AuthAdmin cmp={Trasaction} />} />
      <Route path="/kyc-complete" element={<AuthAdmin cmp={KycComplete} />} />
      <Route path="/withdrwal" element={<AuthAdmin cmp={Withdrwal} />} />
      <Route path="/process" element={<AuthAdmin cmp={Process} />} />
      <Route path="/success" element={<AuthAdmin cmp={Success} />} />
      <Route path="/failed" element={<AuthAdmin cmp={Faild} />} />
      {/* <Route path="/game" element={<AuthAdmin cmp={Game} />} /> */}
      <Route path="/add-funds" element={<AuthAdmin cmp={Addfund2} />} />
      {/* <Route
        path="/funds-transfer"
        element={<AuthAdmin cmp={FoundTransfer} />}
      /> */}
      <Route path="/ludo-Classic" element={<AuthAdmin cmp={Onbid} />} />
      <Route path="/ludo-Classic-auto" element={<AuthAdmin cmp={Onbid2} />} />
      <Route path="/ludo-popular" element={<AuthAdmin cmp={Onbidpopular} />} />
      <Route
        path="/withdrawal-request"
        element={<AuthAdmin cmp={WithdrawalReq} />}
      />
      <Route path="/view-battel/:id" element={<AuthAdmin cmp={ViewBeetel} />} />
    </Routes>
    // </BrowserRouter>
  );
}

export default AppRoutes;
