import React from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";

function Wallet() {
  return (
    <div>
      {" "}
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="p-4 bg-light">
              <a
                className="d-flex align-items-center profile-wallet undefined"
                href="/transaction-history"
              >
                <picture className="ml-4">
                  <img
                    width="32px"
                    src="../assest/image/order-history.png"
                    alt=""
                  />
                </picture>
                <div className="ml-5 mytext text-muted">Order History</div>
              </a>
            </div>
            <div className="divider-x"></div>
            <div className="p-4 bg-light">
              <div
                className="wallet-card"
                //     style="
                //   background-color: rgb(71, 130, 244);
                //   background-image: url('/images/bg-wallets-deposit.png');
                // "
                style={{
                  backgroundColor: "rgb(127, 153, 255)",
                  backgroundImage: "url('/images/bg-wallets-winnings.png')",
                }}
              >
                <div className="d-flex align-items-center">
                  <picture className="mr-1">
                    <img
                      height="26px"
                      width="26px"
                      src="../assest/image/global-rupeeIcon.png"
                      alt=""
                    />
                  </picture>
                  <span
                    className="text-white"
                    style={{ fontSize: "1.3em", fontWeight: "900" }}
                  >
                    ₹ 100
                  </span>
                </div>
                <div
                  className="text-white text-uppercase"
                  style={{ fontSize: "0.9em", fontWeight: "500" }}
                >
                  Deposit Cash
                </div>
                <div
                  className="mt-5"
                  style={{ fontSize: "0.9em", color: "rgb(191, 211, 255)" }}
                >
                  Can be used to play Tournaments &amp; Battles.
                  <br />
                  Cannot be withdrawn to Paytm or Bank.
                </div>
                <Link
                  to={"/add-funds"}
                  // href="#"
                  className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase"
                >
                  Add Cash
                </Link>
              </div>
              <div
                className="wallet-card"
                style={{
                  backgroundColor: "rgb(127, 153, 255)",
                  backgroundImage: "url('/images/bg-wallets-winnings.png')",
                }}
              >
                <div className="d-flex align-items-center">
                  <picture className="mr-1">
                    <img
                      height="26px"
                      width="26px"
                      src="../assest/image/global-rupeeIcon.png"
                      alt=""
                    />
                  </picture>
                  <span
                    className="text-white"
                    style={{ fontSize: "1.3em", fontWeight: "900" }}
                  >
                    ₹ 100
                  </span>
                </div>
                <div
                  className="text-white text-uppercase"
                  //   style="font-size: 0.9em; font-weight: 500"
                  style={{ fontSize: "0.9em", fontWeight: "500" }}
                >
                  Winnings Cash
                </div>
                <div
                  className="mt-5"
                  //   style="font-size: 0.9em; color: rgb(216, 224, 255)"
                  style={{ fontSize: "0.9em", color: "rgb(191, 211, 255)" }}
                >
                  Can be withdrawn to Paytm or Bank. Can be used to play
                  Tournaments &amp; Battles.
                </div>

                <Link
                  to={"/withdrwal"}
                  className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase"
                >
                  Withdraw
                </Link>
                {/* <a
                  href="#"
                  className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase"
                >
                  Withdraw
                </a> */}
              </div>

              <div
                className="wallet-card"
                //     style="
                //   background-color: rgb(71, 130, 244);
                //   background-image: url('/images/bg-wallets-deposit.png');
                // "

                style={{
                  backgroundColor: "rgb(127, 153, 255)",
                  backgroundImage: "url('/images/bg-wallets-winnings.png')",
                }}
              >
                <div className="d-flex align-items-center">
                  <picture className="mr-1">
                    <img
                      height="26px"
                      width="26px"
                      src="../assest/image/global-rupeeIcon.png"
                      alt=""
                    />
                  </picture>
                  <span
                    className="text-white"
                    // style="font-size: 1.3em; font-weight: 900"
                    style={{ fontSize: "1.3em", fontWeight: "900" }}
                  >
                    ₹ 100
                  </span>
                </div>
                {/* <!--<div className="text-white text-uppercase" style="font-size: 0.9em; font-weight: 500;">Deposit Cash</div>--> */}
                <div
                  className="text-white text-uppercase"
                  style={{ fontSize: "0.9em", fontWeight: "500" }}
                >
                  Send To Friends
                </div>
                <div
                  className="mt-5"
                  style={{ fontSize: "0.9em", fontWeight: "500" }}
                >
                  Send Money Your Friends Wallet
                </div>
                <a
                  href="#"
                  className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase"
                >
                  Send Money
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Wallet;
