import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allapiAction } from "../Redux/common/action";
import { SIDERBAR_BOS } from "../Redux/common/constant";
import { useTranslation } from "react-i18next";
import { URL } from "../Redux/common/url";
function SiderBar(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const sideBar_Box = useSelector((state) =>
    state?.allapi?.sideBar_Box ? state?.allapi?.sideBar_Box : false
  );
  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  console.log(profile);
  console.log(profile?.users?.keyverify);

  const dispatch = useDispatch();
  const otpBoxAction = (data) => {
    return { type: SIDERBAR_BOS, payload: data };
  };

  const hendleSideBar = (e) => {
    dispatch(otpBoxAction(e));
  };

  // useEffect(() => {
  //   dispatch(allapiAction.getprofile({}));
  //   return () => {};
  // }, []);

  const logout = async () => {
    if (typeof window !== "undefined") {
      await localStorage.clear();
    }
    hendleSideBar(false);
    navigate("/login");
  };

  const [more, setmore] = useState(true);

  return (
    <div>
      {" "}
      <div
        id="menufade"
        onClick={() => {
          hendleSideBar(false);
        }}
        className={sideBar_Box ? "sideNav-overlay" : ""}
      ></div>
      <div
        id="menulist"
        className={sideBar_Box ? "sideNav sideNav-open" : "sideNav"}
      >
        <div>
          {/* <div className="profileContainer">
            <div style={{ width: "100px", margin: "auto" }}>
              <div className="proimg">
                <img
                  className="border-50"
                  height="95px"
                  width="95px"
                  src={
                    profile?.users?.image
                      ? URL?.API_BASE_URL + profile?.users?.image
                      : "../assest/image/Avatar2.png"
                  }
                  alt=""
                />

                {profile?.users?.keyverify == "complete" ? (
                  <img
                    src="../assest/image/profileverify.png"
                    alt=""
                    className="proimgv"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <img
                    className="proimgv"
                    src="../assest/image/profileunverify.png"
                    alt=""
                    style={{ width: "100%" }}
                  />
                )}
              </div>
            </div>

            <p>{profile?.users?.uid}</p>
            <Link
              onClick={() => {
                hendleSideBar(false);
              }}
              to={"/profile"}
            >
              {t("viewProfile")}
         
            </Link>
          </div> */}

          {/* <div className=" profileContainerChild">
            <div className="row">
              <div className="col-7">
                <h6>₹ {profile?.wallets?.closeBal}</h6>
                <p>Total Balance</p>
              </div>
              <div className="col-5">
                <div className="profileChildRight">
         
                  <div className="profileChildRightbox1">
                    <img src="../assest/image/global-rupeeIcon.png" alt="" />
                  </div>
                  <div className="profileChildRightbox2">
                    <Link
                      to="/add-funds"
                      onClick={() => {
                        hendleSideBar(false);
                      }}
                    >
                      {" "}
                      {t("addcash")}
                    </Link>{" "}
                  </div>
               
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <Link
          to={"/profile"}
          className="sideNav-options"
          onClick={() => {
            hendleSideBar(false);
          }}
        >
          <picture className="sideNav-icon">
            <img
              className="border-50"
              src="../assest/image/Avatar2.png"
              alt="My Profile"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text"> {t("myprofile")}</div>
          </div>
          <picture className="sideNav-arrow">
            <img src="../assest/image/global-black-chevronRight.png" alt="" />
          </picture>
          <div className="sideNav-divider"></div>
        </Link>
        <Link
          to={"/friend-search"}
          className="sideNav-options"
          onClick={() => {
            hendleSideBar(false);
          }}
        >
          <picture className="sideNav-icon">
            <img
              className="border-50"
              src="../assest/image/Avatar2.png"
              alt="My Profile"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text"> {t("friendsearch")}</div>
          </div>
          <picture className="sideNav-arrow">
            <img src="../assest/image/global-black-chevronRight.png" alt="" />
          </picture>
          <div className="sideNav-divider"></div>
        </Link>
        <Link
          to={"/friend-request"}
          className="sideNav-options"
          onClick={() => {
            hendleSideBar(false);
          }}
        >
          <picture className="sideNav-icon">
            <img
              className="border-50"
              src="../assest/image/Avatar2.png"
              alt="My Profile"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text"> {t("friendrequest")}</div>
          </div>
          <picture className="sideNav-arrow">
            <img src="../assest/image/global-black-chevronRight.png" alt="" />
          </picture>
          <div className="sideNav-divider"></div>
        </Link>
        <Link
          to={"/friend-list"}
          className="sideNav-options"
          onClick={() => {
            hendleSideBar(false);
          }}
        >
          <picture className="sideNav-icon">
            <img
              className="border-50"
              src="../assest/image/Avatar2.png"
              alt="My Profile"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text"> {t("friendlist")}</div>
          </div>
          <picture className="sideNav-arrow">
            <img src="../assest/image/global-black-chevronRight.png" alt="" />
          </picture>
          <div className="sideNav-divider"></div>
        </Link>
        {/* <Link
          to={"/funds-transfer"}
          className="sideNav-options"
          onClick={() => {
            hendleSideBar(false);
          }}
        >
          <picture className="sideNav-icon">
            <img
              className="border-50"
              src="../assest/image/Avatar2.png"
              alt="My Profile"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text"> {t("fundsTransfer")}</div>
          </div>
          <picture className="sideNav-arrow">
            <img src="../assest/image/global-black-chevronRight.png" alt="" />
          </picture>
          <div className="sideNav-divider"></div>
        </Link> */}

        {!more && (
          <a
            // to={"/funds-transfer"}
            className="sideNav-options"
            onClick={() => {
              setmore(true);
            }}
          >
            <picture className="sideNav-icon">
              {/* <img
              className="border-50"
              src="../assest/image/Avatar2.png"
              alt="My Profile"
            /> */}
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("more")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
          </a>
        )}

        {/* <Link
          to={"/metrics"}
          className="sideNav-options"
          onClick={() => {
            hendleSideBar(false);
          }}
        >
          <picture className="sideNav-icon">
            <img
              className="border-50"
              src="../assest/image/Avatar2.png"
              alt="My Profile"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text"> {t("metrics")}</div>
          </div>
          <picture className="sideNav-arrow">
            <img src="../assest/image/global-black-chevronRight.png" alt="" />
          </picture>
          <div className="sideNav-divider"></div>
        </Link> */}
        {/* <a
          
          href="#"
        >
          <picture className="sideNav-icon">
            <img
              className="border-50"
              src="../assest/image/avatars/Avatar2.png"
              alt="My Profile"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text">My Profile</div>
          </div>
          <picture className="sideNav-arrow">
            <img
              src="../assest/image/global-black-chevronRight.png"
              alt=""
            />
          </picture>
          <div className="sideNav-divider"></div>
        </a> */}
        {more && (
          <Link
            to={"/"}
            onClick={() => {
              hendleSideBar(false);
            }}
            className="sideNav-options"
          >
            {/* <a className="sideNav-options" href="/public"> */}
            <picture className="sideNav-icon">
              <img
                className=""
                src="../assest/image/gamepad.png"
                alt="Win Cash"
              />
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("wincash")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
            {/* </a> */}
          </Link>
        )}
        {more && (
          <Link
            to={"/wallet"}
            onClick={() => {
              hendleSideBar(false);
            }}
            className="sideNav-options"
          >
            {/* <a
          className="sideNav-options"
          href="#"
        > */}
            <picture className="sideNav-icon">
              <img
                className=""
                src="../assest/image/sidebar-wallet.png"
                alt="My Wallet"
              />
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("mywallet")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
            {/* </a> */}
          </Link>
        )}
        {more && (
          <Link
            to={"/game-history"}
            onClick={() => {
              hendleSideBar(false);
            }}
            className="sideNav-options"
          >
            {/* <a

          className="sideNav-options"
          href="#"
        > */}
            <picture className="sideNav-icon">
              <img
                className=""
                src="../assest/image/sidebar-gamesHistory.png"
                alt="Games History"
              />
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("gamehistory")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
            {/* </a> */}
          </Link>
        )}


        {/* <Link
          to={"/Top-10-Player"}
          onClick={() => {
            hendleSideBar(false);
          }}
          className="sideNav-options"
        >
         \
          <picture className="sideNav-icon">
            <img
              className=""
              src="../assest/image/sidebar-gamesHistory.png"
              alt="Games History"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text">Top 10 Players</div>
          </div>
          <picture className="sideNav-arrow">
            <img src="../assest/image/global-black-chevronRight.png" alt="" />
          </picture>
          <div className="sideNav-divider"></div>
     
        </Link> */}

        {more && (
          <Link
            to={"/transaction"}
            onClick={() => {
              hendleSideBar(false);
            }}
            className="sideNav-options"
          >
            <picture className="sideNav-icon">
              <img
                className=""
                src="../assest/image/order-history.png"
                alt="Transaction History"
              />
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("trHistory")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
            {/* </a> */}
          </Link>
        )}
        {more && (
          <Link
            to={"/withdrawal-request"}
            onClick={() => {
              hendleSideBar(false);
            }}
            className="sideNav-options"
          >
            <picture className="sideNav-icon">
              <img
                className=""
                src="../assest/image/order-history.png"
                alt="Transaction History"
              />
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("withdrawalreq")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
            {/* </a> */}
          </Link>
        )}

        {more && (
          <Link
            to={"/refer-earn"}
            onClick={() => {
              hendleSideBar(false);
            }}
            className="sideNav-options"
          >
            {/* <a
          className="sideNav-options"
          href="#"
        > */}
            <picture className="sideNav-icon">
              <img
                className=""
                src="../assest/image/sidebar-referEarn.png"
                alt="Refer &amp; Earn"
              />
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("referEran")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
            {/* </a> */}
          </Link>
        )}

        {/* <Link
          to={"/"}
          onClick={() => {
            hendleSideBar(false);
          }}
          className="sideNav-options"
        >
         
          <picture className="sideNav-icon">
            <img
              className=""
              src="../assest/image/sidebar-referEarn.png"
              alt="Refer &amp; Earn"
            />
          </picture>
          <div className="position-relative ml-3">
            <div className="sideNav-text">Referral History</div>
          </div>
          <picture className="sideNav-arrow">
            <img src="../assest/image/global-black-chevronRight.png" alt="" />
          </picture>
          <div className="sideNav-divider"></div>
       
        </Link> */}

        {/* {more && (
          <Link
            to={"/notification"}
            onClick={() => {
              hendleSideBar(false);
            }}
            className="sideNav-options"
          >
           
            <picture className="sideNav-icon">
              <img
                className=""
                src="../assest/image/sidebar-notifications.png"
                alt="Notification"
              />
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("notifiction")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
       
          </Link>
        )} */}

        {more && (
          <Link
            to={"/support"}
            onClick={() => {
              hendleSideBar(false);
            }}
            className="sideNav-options"
          >
            <picture className="sideNav-icon">
              <img
                className=""
                src="../assest/image/sidebar-support.png"
                alt="Support"
              />
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> {t("support")}</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
          </Link>
        )}
        {/* <div style={{ textAlign: "left", marginLeft: "20px",marginTop:"30px" }}>Credit</div> */}
        {/* {more && (
          <a
            href={"tel::9045016115"}
            // onClick={() => {
            //   hendleSideBar(false);
            // }}
            className="sideNav-options"
          >
            
            <picture className="sideNav-icon">
              
            </picture>
            <div className="position-relative ml-3">
              <div className="sideNav-text"> Develop By Poize Games</div>
              <div className="sideNav-text">9045016115</div>
            </div>
            <picture className="sideNav-arrow">
              <img src="../assest/image/global-black-chevronRight.png" alt="" />
            </picture>
            <div className="sideNav-divider"></div>
            
          </a>
        )} */}

        {/* <div className="logoutBox">
          <img src="../assest/image/logout.png" alt="" />
          <p onClick={logout}>Log out</p>
        </div>

        <div className="socialmain">
          <a href="#">
            <img src="../assest/image/facebook.png" alt="" />
          </a>
          <a href="#">
            <img src="../assest/image/telegram.png" alt="" />
          </a>
        </div> */}
        <div className="socialmain">
          {/* <a href="#">
            <img src="../assest/image/facebook.png" alt="" />
          </a> */}
          <a target="_blank"  href="https://www.instagram.com/ludobrothers_official/?igsh=Zm5rMTg0NjR1bHFt">
            <img src="../assest/image/instagram.png" alt="" />
          </a>
          <a target="_blank"  href="https://www.youtube.com/@ludobrothers">
            <img src="../assest/image/global-ytPlayIcon.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default SiderBar;
