import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import { useTranslation } from "react-i18next";
function Withdrwal() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  const account_details = useSelector((state) =>
    state?.allapi?.account_details ? state?.allapi?.account_details : {}
  );
  const get_setting = useSelector((state) =>
    state?.allapi?.get_setting ? state?.allapi?.get_setting : {}
  );

  //   useEffect(() => {
  //     setaccountdetailsupdate(account_details);
  //   }, [account_details]);

  useEffect(() => {
    // getaccuontdetails

    dispatch(allapiAction.getaccuontdetails());
    dispatch(allapiAction.getsetting());
    return () => {};
    // setaccountdetailsupdate(profile?.users?.accountId);
  }, []);

  const [amount, setamount] = useState(0);
  console.log(profile);
  const submitbotton = async () => {
    // profile?.wallets?.closeBal

    if (profile?.users?.keyverify == "pending") {
      toast.error("Please wait for kyc verify");
    } else if (profile?.wallets?.closeBal < "reject") {
      toast.error("your kyc reject by admin please complete your details");
    } else if (profile?.wallets?.closeBal < amount) {
      toast.error("your balance Insufficient");
    } else if (get_setting?.minimumwithdrwal > amount) {
      toast.error("minimum withdrawal " + get_setting?.minimumwithdrwal);
    } else if (get_setting?.maxmumwithdrwal < amount) {
      toast.error("maxmum withdrawal " + get_setting?.maxmumwithdrwal);
    } else {
      // withdrawaluserAmount

      const result = await dispatch(
        allapiAction.withdrawaluserAmount({ amount: amount }, navigate)
      );

      return () => {};
    }
  };
  //   keyverify

  return (
    <div>
      <div id="root">
        <SiderBar />
        <div className="leftContainer">
          <Header />
          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="withdrawalBox">
              <div className="wfb">
                <div className="row">
                  <div className="col-6"></div>
                  <div className="col-6"></div>
                </div>
              </div>
            </div>

            <div class="d-flex position-relative align-items-center">
              <div className="px-4 col-12 py-3">
                <div class="games-section-title">
                {t("withdrawaloption")}
                {/* Choose withdrawal option */}
                </div>
                {profile?.users?.accountId == undefined && (
                  <Link
                    class="d-flex align-items-center profile-wallet bg-light m-4 py-3"
                    to="/kyc-complete"
                  >
                    <picture class="ml-4">
                      <img width="32px" src="/images/alert.svg" alt="" />
                    </picture>
                    <div class="ml-4 mytext text-muted ">
                      <span>Complete KYC to take Withdrawals</span>
                    </div>
                  </Link>
                )}
                {profile?.users?.keyverify == "pending" && (
                  <div
                    class="d-flex align-items-center profile-wallet bg-light m-4 py-3"
                    // to="/kyc-complete"
                  >
                    {/* <picture class="ml-4">
                      <img width="32px" src="/images/alert.svg" alt="" />
                    </picture> */}
                    <div class="  col-12 text-muted ">Wait for verify</div>
                  </div>
                )}
                {/* {profile?.users?.keyverify == "pending" && (
                  <div
                    class="d-flex align-items-center profile-wallet bg-light m-4 py-3"
                    // to="/kyc-complete"
                  >
                    <picture class="ml-4">
                      <img width="32px" src="/images/alert.svg" alt="" />
                    </picture>
                    <div class="  text-muted ">
                      Wait for verify
                    </div>
                  </div>
                )} */}
                {profile?.users?.keyverify == "reject" && (
                  <div
                    class="d-flex align-items-center   m-4 py-3"
                    // to="/kyc-complete"
                  >
                    <div class=" col-12  text-muted ">
                      <p className="text-danger">{account_details?.reason}</p>
                      <Link class="btn btn-primary" to="/kyc-complete">
                        kyc update
                      </Link>
                    </div>
                  </div>
                )}

                <div class="mt-3" >
                  {/* <div
                    class="add-fund-box d-flex align-items-center mt-4"
                    style={{paddingTop: "15px", height: "60px"}}
                  >
                    <div class="d-flex align-items-center">
                      <img width="48px" src="/images/upi.webp" alt="" />
                      <div class="d-flex justify-content-center flex-column ml-4">
                        <div class="jss4">Withdraw to UPI</div>
                        <div class="jss5">Minimum withdrawal amount ₹10</div>
                      </div>
                    </div>
                  </div> */}

                  {profile?.users?.accountId &&
                    profile?.users?.keyverify == "complete" && (
                      <div
                        class="d-flex align-items-center   m-4 py-3"
                        // to="/kyc-complete"
                      >
                        <div class=" col-12  text-muted ">
                          <p className="text-muted">
                            Account verified
                            {/* {account_details?.reason} */}
                          </p>
                          <Link class="btn btn-primary" to="/kyc-complete">
                            kyc update
                          </Link>
                        </div>
                      </div>
                    )}
                  {profile?.users?.accountId &&
                    profile?.users?.keyverify == "complete" && (
                      <div
                        class="add-fund-box d-flex align-items-center mt-4"
                        style={{ paddingTop: "15px", height: "60px" }}
                      >
                        <div class="d-flex align-items-center">
                          <img
                            width="48px"
                            src="../assest/image/bank.png"
                            alt=""
                          />
                          
                          <div class="d-flex justify-content-center flex-column ml-4">
                            <div class="jss4">{profile?.users?.accountId?.bankName}</div>
                            <div class="jss5">
                              {/* Minimum withdrawal amount ₹ */}
                              {profile?.users?.accountId?.accountNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {profile?.users?.accountId &&
                    profile?.users?.keyverify == "complete" && (
                      <div>
                        <div style={{ marginTop: "50px" }} class="UIDtext">
                          <div class="kyc-doc-input">
                            <input
                              type="number"
                              name="idNumber"
                              autocomplete="off"
                              id="inpf1"
                              minlength="12"
                              required=""
                              fdprocessedid="4zkm5f"
                              //   value={accountdetailsupdate?.idNumber}
                              onChange={(e) => {
                                setamount(e.target.value);
                              }}
                            />
                            <div class="label"> {t("enteramount")}</div>
                          </div>
                        </div>
                        <p> {t("bankinstats")}  {t("min")} : {get_setting?.minimumwithdrwal} ,  {t("max")} :- {get_setting?.maxmumwithdrwal}  </p>
                      </div>
                    )}

                  {profile?.users?.accountId &&
                    profile?.users?.keyverify == "complete" && (
                      <div class="refer-footer">
                        <input
                          type="submit"
                          class="disabledButton btn btn-success btn-lg"
                          style={{ width: "100%" }}
                          value="Withdrawal"
                          id="next"
                          onClick={() => {
                            submitbotton();
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Withdrwal;
