import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import ImageUploading from "react-images-uploading";
import { URL } from "../Redux/common/url";

function KycComplete() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [accountdetails, setaccountdetails] = useState({});
  const [accountdetailsupdate, setaccountdetailsupdate] = useState({});
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();

  const [images, setImages] = React.useState([]);
  const [imagesqrcodeUpdate, setImagesqrcodeUpdate] = React.useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
 
    setImages(imageList);
  };
  const onChangeqrcodeUpdate = (imageList, addUpdateIndex) => {
    // data for submit
 
    setImagesqrcodeUpdate(imageList);
  };

  const hendeltochange = (e) => {
    const { name, value } = e.target;
    setaccountdetails({ ...accountdetails, [name]: value });
  };
  const hendeltochangeupdate = (e) => {
    const { name, value } = e.target;
    setaccountdetailsupdate({ ...accountdetailsupdate, [name]: value });
  };

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  const account_details = useSelector((state) =>
    state?.allapi?.account_details ? state?.allapi?.account_details : {}
  );

 

  useEffect(() => {
    setaccountdetailsupdate(account_details);
    setImages([{ data_url: URL.API_BASE_URL + account_details.idImage }]);
    setImagesqrcodeUpdate([
      { data_url: URL.API_BASE_URL + account_details.qrcode },
    ]);
    // setImages
  }, [account_details]);

  useEffect(() => {
    // getaccuontdetails

    dispatch(allapiAction.getaccuontdetails());
    return () => {};
    // setaccountdetailsupdate(profile?.users?.accountId);
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleFileChange2 = (e) => {
    if (e.target.files) {
      setFile2(e.target.files[0]);
    }
  };

  const submitdetails = () => {
    if (!accountdetails?.idType) {
      toast.error("please select document ");
    } else if (!accountdetails?.idNumber) {
      toast.error("please select document number");
    } else if (!file) {
      toast.error("please select document file");
    } else if (!accountdetails?.bankName) {
      toast.error("please enter bank name ");
    } else if (!accountdetails?.accountNumber) {
      toast.error("please enter Account number ");
    } else if (!accountdetails?.ifseCode) {
      toast.error("please enter ifse code ");
    } else if (!accountdetails?.userName) {
      toast.error("please enter Account Holder name ");
    } else {
      const data1 = new FormData();

      // const bettelId = data?.id;

 
      data1.append("idType", accountdetails?.idType);
      data1.append("idNumber", accountdetails?.idNumber);
      data1.append("bankName", accountdetails?.bankName);
      data1.append("accountNumber", accountdetails?.accountNumber);
      data1.append("ifseCode", accountdetails?.ifseCode);
      data1.append("userName", accountdetails?.userName);
      data1.append("upiId", accountdetails?.upiId);
      // data1.append("status", result);
      data1.append("image", file);
      data1.append("qrcode", file2);

      dispatch(allapiAction.createaccount(data1, navigate));
      return () => {};
    }
  };
  const submitdetailsupdate = () => {
    if (!accountdetailsupdate?.idType) {
      toast.error("please select document ");
    } else if (!accountdetailsupdate?.idNumber) {
      toast.error("please select document number");
    } else if (!accountdetailsupdate?.bankName) {
      toast.error("please enter bank name ");
    } else if (!accountdetailsupdate?.accountNumber) {
      toast.error("please enter Account number ");
    } else if (!accountdetailsupdate?.ifseCode) {
      toast.error("please enter ifse code ");
    } else if (!accountdetailsupdate?.userName) {
      toast.error("please enter Account Holder name ");
    } else {
      const data1 = new FormData();

      // const bettelId = data?.id;

      
      data1.append("_id", accountdetailsupdate?._id);
      data1.append("idType", accountdetailsupdate?.idType);
      data1.append("idNumber", accountdetailsupdate?.idNumber);
      data1.append("bankName", accountdetailsupdate?.bankName);
      data1.append("accountNumber", accountdetailsupdate?.accountNumber);
      data1.append("ifseCode", accountdetailsupdate?.ifseCode);
      data1.append("userName", accountdetailsupdate?.userName);
      data1.append("upiId", accountdetailsupdate?.upiId);
      // data1.append("status", result);

      // if (file) {
      //   // toast.error("please select document file");
      //   data1.append("image", file);
      // }

      if (images[0]?.file) {
        // roledata?.role_name
        data1.append("image", images[0]?.file);
      }
      if (imagesqrcodeUpdate[0]?.file) {
        // roledata?.role_name
        data1.append("qrcode", imagesqrcodeUpdate[0]?.file);
      }

      dispatch(allapiAction.updateaccount(data1, navigate));
      return () => {};
    }
  };

  return (
    <div>
      <div id="root">
        <SiderBar />
        <div className="leftContainer">
          <Header />
          {profile?.users?.keyverify == "required" && (
            <div class="kycPage">
              <div>
                <span style={{ fontSize: "1.5em" }}>Step 1</span> of 3
              </div>
              <p class="mt-2" style={{ color: "rgb(149, 149, 149)" }}>
                You need to submit a document that shows that you are
                <span style={{ fontWeight: "500" }}>above 18 years</span> of age
                and not a resident of
                <span style={{ fontWeight: "500" }}>
                  Assam, Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh,
                  Tamil Nadu and Karnataka.
                </span>
              </p>
              <br />
              {/* <form action="#" method="post"> */}
              {/* <input
                type="hidden"
                name="_token"
                value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
              /> */}
              <div>
                <span
                  style={{ color: "rgb(149, 149, 149)", fontWeight: "500" }}
                >
                  Document Type
                </span>

                <select
                  name="idType"
                  id="target"
                  class="kyc-input-text form-control kyc-input mt-2"
                  style={{ width: "100%", padding: "10px" }}
                  fdprocessedid="9t9r1b"
                  onChange={(e) => {
                    hendeltochange(e);
                  }}
                >
                  {/* <!--onchange="showOtherOptions(this.value)"--> */}
                  <option value="">Select Document</option>
                  <option value="1" style={{ fontSize: "15px" }}>
                    Adhar Card
                  </option>
                  <option value="2" style={{ fontSize: "15px" }}>
                    Driving Licence
                  </option>
                  <option value="3" style={{ fontSize: "15px" }}>
                    Voter ID
                  </option>
                </select>
              </div>

              <div id="UID" class="vis" style={{ marginBottom: "150px" }}>
                <input
                  type="hidden"
                  name="_token"
                  value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
                />
                <input type="hidden" name="DOCUMENT_NAME" value="UID" />
                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="idNumber"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        hendeltochange(e);
                      }}
                    />
                    <div class="label">Document Number</div>
                  </div>
                </div>

                {/* image */}

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="file"
                      name="image"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                    <div class="label">Document ID</div>
                  </div>
                </div>
                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="file"
                      name="image"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        handleFileChange2(e);
                      }}
                    />
                    <div class="label">Qr ID</div>
                  </div>
                </div>

                <div style={{ marginTop: "30px" }}>
                  <h6>Account Details</h6>
                </div>

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="bankName"
                      autocomplete="off"
                      // id="inpf1"
                      // minlength="12"
                      // required=""
                      // fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        hendeltochange(e);
                      }}
                    />
                    <div class="label">Bank Name</div>
                  </div>
                </div>

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="accountNumber"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        hendeltochange(e);
                      }}
                    />
                    <div class="label">Account Number</div>
                  </div>
                </div>

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="ifseCode"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        hendeltochange(e);
                      }}
                    />
                    <div class="label">Ifse Code</div>
                  </div>
                </div>
                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="userName"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        hendeltochange(e);
                      }}
                    />
                    <div class="label">Account Holder Name</div>
                  </div>
                </div>
                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="upiId"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        hendeltochange(e);
                      }}
                    />
                    <div class="label">upi Id</div>
                  </div>
                </div>
                {/* <div style={{ marginTop: "50px" }} class="UIDtext">
                <div class="kyc-doc-input">
                  <input
                    type="number"
                    name="DOCUMENT_NUMBER"
                    autocomplete="off"
                    id="inpf1"
                    minlength="12"
                    required=""
                    fdprocessedid="4zkm5f"
                  />
                  <div class="label">Enter Aadhaar Card Number</div>
                </div>
              </div> */}
              </div>
              <div class="refer-footer">
                <input
                  type="submit"
                  class="disabledButton btn btn-primary btn-lg"
                  style={{ width: "100%" }}
                  value="Submit"
                  id="next"
                  onClick={() => {
                    submitdetails();
                  }}
                />
              </div>
            </div>
          )}

          {profile?.users?.keyverify == "pending" && (
            <div class="main-area" style={{ paddingTop: "60px" }}>
              <div class="cxy flex-column mx-5 mt-5">
                <picture class="ml-4" style={{ width: "80%", height: "auto" }}>
                  <img
                    src="../assest/image/kyc.png"
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </picture>
                <div class="font-11 mt-4">
                  Your KYC is in <span class="text-danger">Validation</span>
                </div>
                <div class="my-3 text-center" style={{ width: "100%" }}>
                  <div class="footer-text" style={{ fontSize: "0.9em" }}>
                    We are verifying your details. You will be notified when
                    your KYC is completed.
                  </div>
                </div>
              </div>
            </div>
          )}

          {(profile?.users?.keyverify == "reject" ||
            profile?.users?.keyverify == "complete") && (
            <div class="kycPage">
              <div>
                <span style={{ fontSize: "1.5em" }}>Step 1</span> of 3
              </div>
              {profile?.users?.keyverify == "reject" && (
                <p class="mt-2" style={{ color: "red" }}>
                  {account_details?.reason}
                </p>
              )}
              <br />
              {/* <form action="#" method="post"> */}
              {/* <input
                type="hidden"
                name="_token"
                value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
              /> */}
              <div>
                <span
                  style={{ color: "rgb(149, 149, 149)", fontWeight: "500" }}
                >
                  Document Type
                </span>

                <select
                  name="idType"
                  id="target"
                  class="kyc-input-text form-control kyc-input mt-2"
                  style={{ width: "100%", padding: "10px" }}
                  fdprocessedid="9t9r1b"
                  value={accountdetailsupdate?.idType}
                  onChange={(e) => {
                    hendeltochangeupdate(e);
                  }}
                >
                  {/* <!--onchange="showOtherOptions(this.value)"--> */}
                  <option value="">Select Document</option>
                  <option value="1" style={{ fontSize: "15px" }}>
                    Adhar Card
                  </option>
                  <option value="2" style={{ fontSize: "15px" }}>
                    Driving Licence
                  </option>
                  <option value="3" style={{ fontSize: "15px" }}>
                    Voter ID
                  </option>
                </select>
              </div>

              <div id="UID" class="vis" style={{ marginBottom: "150px" }}>
                {/* <input
                  type="hidden"
                  name="_token"
                  value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
                /> */}
                <input type="hidden" name="DOCUMENT_NAME" value="UID" />
                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="idNumber"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      value={accountdetailsupdate?.idNumber}
                      onChange={(e) => {
                        hendeltochangeupdate(e);
                      }}
                    />
                    <div class="label">Document Number</div>
                  </div>
                </div>

                {/* image */}

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            className="btn btn-secondary"
                            style={isDragging ? { color: "red" } : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Choose image
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary"
                            onClick={onImageRemoveAll}
                          >
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                    {/* <input
                      type="file"
                      name="image"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    /> */}
                    <div class="label">Document ID</div>
                  </div>
                </div>

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <ImageUploading
                      multiple
                      value={imagesqrcodeUpdate}
                      onChange={onChangeqrcodeUpdate}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            className="btn btn-secondary"
                            style={isDragging ? { color: "red" } : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Choose image
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary"
                            onClick={onImageRemoveAll}
                          >
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                    {/* <input
                      type="file"
                      name="image"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    /> */}
                    <div class="label">Document ID</div>
                  </div>
                </div>

                <div style={{ marginTop: "30px" }}>
                  <h6>Account Details</h6>
                </div>

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="bankName"
                      autocomplete="off"
                      value={accountdetailsupdate?.bankName}
                      // id="inpf1"
                      // minlength="12"
                      // required=""
                      // fdprocessedid="4zkm5f"
                      onChange={(e) => {
                        hendeltochangeupdate(e);
                      }}
                    />
                    <div class="label">Bank Name</div>
                  </div>
                </div>

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="accountNumber"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      value={accountdetailsupdate?.accountNumber}
                      onChange={(e) => {
                        hendeltochangeupdate(e);
                      }}
                    />
                    <div class="label">Account Number</div>
                  </div>
                </div>

                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="ifseCode"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      value={accountdetailsupdate?.ifseCode}
                      onChange={(e) => {
                        hendeltochangeupdate(e);
                      }}
                    />
                    <div class="label">Ifse Code</div>
                  </div>
                </div>
                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="userName"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      value={accountdetailsupdate?.userName}
                      onChange={(e) => {
                        hendeltochangeupdate(e);
                      }}
                    />
                    <div class="label">Account Holder Name</div>
                  </div>
                </div>
                <div style={{ marginTop: "50px" }} class="UIDtext">
                  <div class="kyc-doc-input">
                    <input
                      type="text"
                      name="upiId"
                      autocomplete="off"
                      id="inpf1"
                      minlength="12"
                      required=""
                      fdprocessedid="4zkm5f"
                      value={accountdetailsupdate?.upiId}
                      onChange={(e) => {
                        hendeltochangeupdate(e);
                      }}
                    />
                    <div class="label">upi Id</div>
                  </div>
                </div>
                {/* <div style={{ marginTop: "50px" }} class="UIDtext">
                <div class="kyc-doc-input">
                  <input
                    type="number"
                    name="DOCUMENT_NUMBER"
                    autocomplete="off"
                    id="inpf1"
                    minlength="12"
                    required=""
                    fdprocessedid="4zkm5f"
                  />
                  <div class="label">Enter Aadhaar Card Number</div>
                </div>
              </div> */}
              </div>
              <div class="refer-footer">
                <input
                  type="submit"
                  class="disabledButton btn btn-primary btn-lg"
                  style={{ width: "100%" }}
                  value="Update"
                  id="next"
                  onClick={() => {
                    submitdetailsupdate();
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default KycComplete;
