import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";

function ReferEran() {
  const dispatch = useDispatch();

  const get_setting = useSelector((state) =>
    state?.allapi?.get_setting ? state?.allapi?.get_setting : {}
  );

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

 

  const referuri =
    window.location.origin + "/login/" + profile?.users?.referCode;

 

  useEffect(() => {
    // getaccuontdetails

    // dispatch(allapiAction.getaccuontdetails());
    dispatch(allapiAction.getsetting());
    return () => {};
    // setaccountdetailsupdate(profile?.users?.accountId);
  }, []);
  return (
    <div>
      {" "}
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        {/* <!------Nav Menu End------>

    <!------Hedar Start------> */}

        <SiderBar />

        <div className="leftContainer">
          <Header />
          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="center-xy">
              <picture className="mt-1">
                <img
                  width="226px"
                  src="../assest/image/referral-user-welcome.png"
                  alt=""
                />
              </picture>
              <div className="mb-1">
                <div className="font-15">
                  Earn now unlimited
                  <span role="img" aria-label="party-face">
                    🥳
                    
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  Refer your friends now!
                </div>
                <div className="text-bold mt-3 text-center">
                  Your Refer Code: {profile?.users?.referCode}
                  <img
                    className="ml-2"
                    width="20px"
                    src="/images/icon-edit.jpg"
                    alt=""
                  />
                </div>
                <div className="d-flex justify-content-center">
                  Total Refers:&nbsp;<b>0</b>
                </div>
                <div className="d-flex justify-content-center">
                  Total Earnings:&nbsp;
                  <b>
                    <img
                      src="../assest/image/global-rupeeIcon.png"
                      alt=""
                      width="20px;"
                    />
                    ₹0
                    <a href="#">Reedem</a>
                  </b>
                </div>
              </div>
            </div>
            <div className="divider-x"></div>
            <div className="mx-3 my-3">
              <div className="font-11">Refer &amp; Earn Rules</div>
              <div className="d-flex align-items-center m-3">
                <picture>
                  <img
                    width="82px"
                    src="../assest/image/referral-signup-bonus-new.png"
                    alt=""
                  />
                </picture>
                <div className="font-9 mx-3" style={{ width: "63%" }}>
                  <div>
                    When your friend signs up on Vplay from your referral link,
                  </div>
                  <div className="font-8 c-green mt-2">
                    You get <strong>4% Commission</strong> on your
                    <strong>referral's winnings.</strong>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center m-3">
                <picture>
                  <img
                    width="82px"
                    src="../assest/image/banner_illsutration.png"
                    alt=""
                  />
                </picture>
                <div className="font-9 mx-3" style={{ width: "63%" }}>
                  <div>
                    Suppose your referral plays a battle for ₹10000 Cash,
                  </div>
                  <div className="font-8 c-green mt-2">
                    You get <strong>₹400 Cash</strong> <strong></strong>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingBottom: "80px" }}></div>
            <div className="refer-footer">

              {/* <a
                href="whatsapp://send?text=Play Ludo and earn ₹10000 daily.  https://test.test/referral/GH1351   
           
            Register Now, My refer code&nbsp;is GH1351."
                className="bg-green refer-button cxy w-100"
              >
                Share in Whatsapp
              </a>
              <a
                href="whatsapp://send?text=The text to share!"
                data-action="share/whatsapp/share"
              >
                Share via Whatsapp
              </a>
              <a
                href="https://web.whatsapp.com/send?text=textToshare"
                target="_blank"
              >
                Share via Whatsapp
              </a> */}
              <a
                href={
                  `https://wa.me/?text=Play Ludo and earn ₹10000 daily.  ${referuri} Register Now, My refer code is ` +
                  profile?.users?.referCode
                }
                class="bg-green refer-button cxy w-100"
                target="_blank"
              >
                Share in Whatsapp
              </a>
              {/* 
              <button className="refer-button-copy ml-2 d-flex">
                <picture>
                  <img
                    height="18px"
                    width="18px"
                    src="../assest/image/global-copy-grey.png"
                    alt=""
                  />
                </picture>
              </button> */}
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default ReferEran;
