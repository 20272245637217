export const LOADING = "LOADING";
//user constant
export const SIDERBAR_BOS = "SIDERBAR_BOS";

export const CREATE_CATEGORIES = "CREATE_CATEGORIES";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SUB_SUB_CATEGORIES = "GET_SUB_SUB_CATEGORIES";
export const PROFILE = "PROFILE";
export const BATTEL_LIST = "BATTEL_LIST";
export const BATTEL_LIST_USERS = "BATTEL_LIST_USERS";
export const BATTEL_DETAILS = "BATTEL_DETAILS";
export const GET_TRANSATION = "GET_TRANSATION";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const ACCOUNT_DETAILS = "ACCOUNT_DETAILS";
export const GET_SETTING = "GET_SETTING";
export const GET_WITHDRAWAL_LIST = "GET_WITHDRWAL_LIST";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_FRIEND_REQ_LIST = "GET_FRIEND_REQ_LIST";
export const GET_METRICS = "GET_METRICS";
export const GET_MESSAGE = "GET_MESSAGE";
export const BATTEL_LIST_2 = "BATTEL_LIST_2";
