import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TestBtn from "./TestBtn";
function Onbid2() {
  const [amount, setamount] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  const battel_List = useSelector((state) =>
    state?.allapi?.battel_List ? state?.allapi?.battel_List : {}
  );
  // createbattel
  const battel_List_2 = useSelector((state) =>
    state?.allapi?.battel_List_2 ? state?.allapi?.battel_List_2 : []
  );
  const dispatch = useDispatch();
  const [startBtmodal2, setstartBtmodal2] = useState(false);

  const handleClose2 = () => setstartBtmodal2(false);

  useEffect(() => {
    // battel_List
    dispatch(allapiAction.getbattellist("classicauto"));
    dispatch(allapiAction.getbattellist2("classicmanual"));
    return () => {};
  }, []);
  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );
  // useEffect(() => {
  //   // battel_List

  //   const setInt = setInterval(() => {
  //     dispatch(allapiAction.getbattellist());
  //     return () => {};
  //   }, 5000);

  //   // return clearInterval(setInt);

  //   return () => {
  //     clearInterval(setInt);
  //   };
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(allapiAction.getbattellist("classicauto"));
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const [betbtn, setbetbtn] = useState(true);
  const hendleTobet = async () => {
    const balencecheck =
      profile?.wallets?.closeBal + profile?.wallets?.winningBal;

    if (!amount) {
      toast.error("please enter amount");
    } else if (amount > balencecheck) {
      toast.error("your balance Insufficient");
    } else {
      let num = Math.random() * (99999 - 10000) + 99999;
      const roomcode = Math.floor(num);
      setbetbtn(false);
      await dispatch(
        allapiAction.createbattel({
          amount,
          roomcode,
          batteltype: "classicauto",
        })
      );
      dispatch(allapiAction.getbattellist("classicauto"));
      setbetbtn(true);
      setamount("");
      return () => {};
    }
  };

  const [joinbtn, setjoinbtn] = useState(true);

  const hendleTojoinBettel = async (e) => {
    // joinbettel
    setjoinbtn(false);
    await dispatch(allapiAction.joinbettel({ _id: e }));
    dispatch(allapiAction.getbattellist("classicauto"));
    setjoinbtn(true);
    return () => {};
  };

  // startbettel

  const [startBt, setstartBt] = useState({});
  const [startBtmodal, setstartBtmodal] = useState(false);

  const handleClose = () => setstartBtmodal(false);
  const handleShow = () => setstartBtmodal(true);

  console.log(startBt);

  const hendletochange = (e) => {
    const { name, value } = e.target;
    setstartBt({ ...startBt, [name]: value });
  };
  const [startbtnshow, setstartbtnshow] = useState(true);
  const hnedletoSubmitform = async () => {
    setstartbtnshow(false);
    if (!startBt?.roomcode) {
      toast.error("please enter room code");
      setstartbtnshow(true);
    } else if (startBt?.roomcode?.length !== 8) {
      toast.error(" room code invalid");
      setstartbtnshow(true);
    } else {
      await dispatch(
        allapiAction.startbettel({
          _id: startBt?.e,
          user: startBt?.i,
          roomcode: startBt?.roomcode,
        })
      );
      dispatch(allapiAction.getbattellist("classicauto"));
      setstartBtmodal(false);
      setstartbtnshow(true);
      setTimeout(() => {
        navigate("/view-battel/" + startBt?.e);
      }, 1000);
    }
  };

  const hendletoStart = async (e, i, roomcode) => {
    console.log(roomcode);
    setstartbtnshow(false);
    if (i == "o") {
      //   if (roomcode) {
      console.log("roomcode");

      await dispatch(allapiAction.startbettel2({ _id: e, user: i }));
      dispatch(allapiAction.getbattellist("classicauto"));
      setstartbtnshow(true);
      setTimeout(() => {
        navigate("/view-battel/" + e);
      }, 1000);
      //   } else {
      //     console.log("no roomcode");
      //     setstartBtmodal(true);
      //     setstartBt({ e, i });
      //   }
    } else {
      await dispatch(allapiAction.startbettel({ _id: e, user: i }));
      dispatch(allapiAction.getbattellist("classicauto"));
      setstartbtnshow(true);
      setTimeout(() => {
        navigate("/view-battel/" + e);
      }, 1000);
    }

    return () => {};
  };

  const [cancilbtn, setcancilbtn] = useState(true);
  const hendlecencelBettel = async (e) => {
    // cencelBettel
    setcancilbtn(false);
    const getdatadone = await dispatch(allapiAction.cencelBettel({ _id: e }));
    dispatch(allapiAction.getbattellist("classicauto"));
    setcancilbtn(true);

    return () => {};
  };


  const testfc = ()=>{
    console.log("testfc")
  }

  const [items, setItems] = useState(['First item', 'Second item', 'Third item']);

  const removeItem = (indexToRemove) => {
    setItems(prevItems => prevItems.filter((_, index) => index !== indexToRemove));
  };
  return (
    <div>
      <div id="root">


        <SiderBar />
        <Modal show={startBtmodal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Room Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Room code</label>
            <input
              name="roomcode"
              className="form-control"
              placeholder="enter room code"
              type={"number"}
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {startbtnshow && (
              <Button variant="primary" onClick={hnedletoSubmitform}>
                Submit
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={startBtmodal2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Rules</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ol>
              <li>यदि दोनों प्लेयर्स ने कोड Join करके गेम प्ले कर लिया हो और दूसरा प्लेयर गोटी ओपन होने के बाद गेम लेफ्ट मारता है तो Opponent प्लेयर पूरा Lose दिया जायेगा</li>
              <li>यदि दोनों Players गेम प्ले के लिए स्टार्ट करते है एक प्लेयर गेम मे चला गया बल्कि दूसरा प्लेयर गेम मैं नही गया और गेम प्ले हो गया दोनों तरफ से एक प्लेयर की गोटी ओपन हो गई और दूसरा प्लेयर ऑटो Exit है जो ऑटो एग्जिट प्लेयर है वो पूरा लूज़ दिया जायेगा अतः अपना नेट प्रॉब्लम चेक करके खेले ये स्वयं की जिम्मेदारी होगी</li>
              <li>Game समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है अन्यथा Opponent के रिजल्ट के आधार पर गेम अपडेट कर दिया जायेगा चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी इसमें बाद में कोई बदलाव नहीं किया जा सकता है !</li>
              <li>Win होने के बाद आप गलत स्क्रीनशॉट डालते है तो गेम को सीधा Cancel कर दिया जायेगा इसलिए यदि आप स्क्रीनशॉट लेना भूल गए है तो पहले Live Chat में एडमिन को संपर्क करे उसके बाद ही उनके बताये अनुसार रिजल्ट पोस्ट करे !</li>
              <li>दोनों प्लेयर की टोकन (काटी) घर से बाहर न आयी हो तो लेफ्ट होकर गेम कैंसिल किया जा सकता है ! [कैंसिल प्रूफ करने के लिए वीडियो आवश्यक होगा]</li>
              <li>कैंसिल' रिजल्ट डालने के बाद गेम प्ले करके जीत जाते है तो उसमे हमारी कोई ज़िम्मेदारी नहीं होगी अतः गेम कैंसिल करने के बाद स्टार्ट न करे</li>
              <li>अन्यथा वो कैंसिल ही माना जायेगा</li>
              <li>एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है इसलिए सोच समझकर रिजल्ट पोस्ट करे गलत रिजल्ट डालने पर पेनल्टी भी लगायी जाएगी चाहे आपने वो गलती से डाला हो या जान भुजकर</li>
              <li>Fresh आईडी गेम के मामले में केवल पासा के उपयोग के प्रमाण पर रद्द कर दिया जाएगा गोटी के खुले होने के बावजूद नहीं ? गेम का रिकॉड होना जरूरी है अगर कोई अपनी आईडी का नाम नो फ्रेश कर रका तो नो फ्रेश आईडी ही टेक करे जिनका इंटरेस्ट हो वही टेक करे टेबल को ( इसका अंतिम रिजल्ट एडमिन देगा )</li>
            </ol>
            <h6>कमीशन दरें:</h6>
            <ol>
              <li>Battle below 250₹, 10% commission will be charged on battle amount. <br /> 250₹ से कम की बैटल, बैटल राशि पर 10% कमीशन लिया जाएगा।</li>
              <li>Battle between 250₹ to 500₹, flat 25₹ commission will be charged. <br /> 250₹ से 500₹ के बीच बैटल, फ्लैट 25₹ कमीशन लगेगा।</li>
              <li>Battle above 500₹, 5% commission will be charged on battle amount. <br /> 500₹ से ऊपर की बैटल, बैटल राशि पर 5% कमीशन लिया जाएगा।</li>
            </ol>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {startbtnshow && (
              <Button variant="primary" onClick={hnedletoSubmitform}>
                Submit
              </Button>
            )}
          </Modal.Footer> */}
        </Modal>

        <div className="leftContainer">
          <Header />
          <div className="main-area" style={{ paddingTop: "60px" }}>

          
          {/* <TestBtn title="New Btn" fonc={testfc} /> */}
            {get_message?.sec && (
              <div className="collapseCard-container ">
                <div className="collapseCard   text-danger ">
                  <Link to="/refer-earn" style={{ textDecoration: "none" }}>
                    <div
                      className="collapseCard-body"
                      style={{
                        height: "64px",
                        opacity: "1",
                        transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                      }}
                    >
                      <div className="collapseCard-text text-danger">
                        {get_message?.sec?.message}
                      </div>
                      <picture className="collapseCard-closeIcon">
                        <img
                          className="position-relative"
                          src="../assest/image/right-arrow.png"
                          alt=""
                          width="18px"
                          height="18px"
                        />
                      </picture>
                    </div>
                  </Link>

                  {/* <div
                    className="collapseCard-header"
                    style={{ left: "22px", transition: "left 0.3s ease 0s" }}
                  >
                    <picture>
                      <img
                        height="10px"
                        width="14px"
                        src="../assest/image/global-ytPlayIcon.png"
                        alt=""
                      />
                    </picture>
                    <div className="collapseCard-title ms-1 mt-1">
                      Importent
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            <span className="cxy battle-input-header"> {t("createB")} !</span>
            <div className="d-flex my-2 w-60 resp-r">
              {/* <form
                action="#"
                method="post"
              > */}
              <input type="hidden" name="game_url" value="Ludo Classic" />
              <input
                type="hidden"
                name="_token"
                value="pwtj6jSvstvLfqPffS28RPyLpXHzgg4znuE6vxMQ"
              />
              <div>
                <input
                  className="form-control"
                  type="tel"
                  name="amount"
                  placeholder="Amount"
                  // value=""
                  required=""
                  style={{ width: "79%" }}
                  fdprocessedid="z9u55"
                  value={amount}
                  onChange={(e) => {
                    setamount(e.target.value);
                  }}
                />
                {betbtn && (
                  <button
                    className="bg-green playButton cxy position-static"
                    type="submit"
                    style={{
                      margin: "-35px",
                      marginRight: "-27px",
                      float: "right",
                    }}
                    fdprocessedid="7cbymi"
                    onClick={() => {
                      hendleTobet();
                    }}
                  >
                    Set
                  </button>
                )}
              </div>
            </div>

            <div className="divider-x"></div>

            <div className="px-4 py-3 position-relative">
              <div className="mb-2">
                <img
                  src="../assest/image/global-battleIconWhiteStroke.png"
                  width="20px"
                  alt=""
                />
                <span className="ms-2 games-section-title">
                  {t("opne")} Battles
                </span>
                <a href="#" onClick={() => { setstartBtmodal2(true) }}>
                  <span
                    className="games-section-headline text-uppercase position-absolute mt-2 font-weight-bold"
                    style={{ right: " 1.5rem", top: "0.7rem" }}
                  >
                    Rules
                    <img
                      className="ms-2"
                      src="../assest/image/global-grey-iButton.png"
                      alt=""
                    />
                  </span>
                </a>
              </div>
              <div id="myOpenBattles"></div>

              {/* <!--//Open Battle is here--> */}
              <div id="openBattle">
                {/* <!-- game is created--> */}
                {battel_List?.opne &&
                  battel_List?.opne?.map((data, i) => {
                    const fdta = i == 0 ? "":i*100
                    return (
                      <div
                        // id="6345971c71f80dea71bf71cf"
                        className="betCard mt-1"
                        data-aos="fade-right"
                        data-aos-delay={fdta}
                        key={i}
                      >
                        <span className="betCard-title pl-3 d-flex align-items-center justify-content-around text-uppercase">
                          CHALLENGE FROM
                          <span className="ms-1" style={{ color: "brown" }}>
                            {data?.owner?.uid}
                          </span>
                          {profile?.users?._id == data?.owner?._id &&
                            data?.player2 == "true" &&
                            startbtnshow && (
                              <a
                                href="#"
                                onClick={() => {
                                  hendletoStart(
                                    data?._id,
                                    profile?.users?._id == data?.owner?._id
                                      ? "o"
                                      : "i",
                                    data?.roomcode
                                  );
                                }}
                                className="btn btn-success btn-xs btn-req"
                              >
                                start
                              </a>
                            )}
                          {profile?.users?._id == data?.playerone?._id &&
                            data?.start1 == "true" &&
                            startbtnshow && (
                              <a
                                href="#"
                                onClick={() => {
                                  hendletoStart(
                                    data?._id,
                                    profile?.users?._id == data?.owner?._id
                                      ? "o"
                                      : "i"
                                  );
                                }}
                                className="btn btn-success btn-xs btn-req"
                              >
                                start
                              </a>
                            )}
                          {profile?.users?._id == data?.owner?._id &&
                            data?.player2 == "false" &&
                            cancilbtn && (
                              <a
                                href="#"
                                onClick={(e) => {
                                  hendlecencelBettel(data?._id);
                                }}
                                className="btn btn-danger btn-xs btn-can"
                              >
                                Cancel
                              </a>
                            )}
                        </span>

                        <div className="d-flex pl-3 justify-content-around">
                          <div className="pr-3 pb-1">
                            <span className="betCard-subTitle">Entry Fee</span>
                            <div>
                              <img
                                src="../assest/image/global-rupeeIcon.png"
                                width="21px"
                                alt=""
                              />
                              <span className="betCard-amount">
                                {data?.amount}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="betCard-subTitle">Prize</span>
                            <div>
                              <img
                                src="../assest/image/global-rupeeIcon.png"
                                width="21px"
                                alt=""
                              />
                              <span className="betCard-amount">
                                {data?.prize}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: "17px",
                              marginLeft: "14px",
                              fontSize: "11px",
                            }}
                            align="center"
                          >
                            <div align="center">
                              {profile?.users?._id !== data?.owner?._id &&
                                data?.player2 == "false" &&
                                joinbtn && (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      hendleTojoinBettel(data?._id);
                                    }}
                                    className="btn btn-secondary btn-xs btn-req"
                                  >
                                    PLAY
                                  </a>
                                )}
                            </div>
                            {profile?.users?._id == data?.owner?._id &&
                              data?.player2 == "true" && (
                                <div className="text-center col-5">
                                  <div className="pl-2">
                                    <img
                                      className="border-50"
                                      src="../assest/image/Avatar2.png"
                                      width="21px"
                                      height="21px"
                                      alt=""
                                    />
                                  </div>
                                  <div style={{ lineHeight: 1 }}>
                                    <span className="betCard-playerName">
                                      {data?.playerone?.uid}
                                    </span>
                                  </div>
                                </div>
                              )}
                            {profile?.users?._id == data?.owner?._id &&
                              data?.player2 == "false" && (
                                <div className="text-center ">
                                  <div className="pl-2">
                                    <img
                                      className="border-50"
                                      src="../assest/image/loader.png"
                                      width="21px"
                                      height="21px"
                                      alt=""
                                    />
                                  </div>
                                  <div style={{ lineHeight: 1 }}>
                                    <span className="">Finding Player!</span>
                                  </div>
                                </div>
                              )}
                            {profile?.users?._id == data?.playerone?._id &&
                              data?.player2 == "true" &&
                              data?.start1 == "false" &&
                              startbtnshow && (
                                <div className="text-center ">
                                  <div className="pl-2">
                                    {/* <img
                                      className="border-50"
                                      src="../assest/image/loader.png"
                                      width="21px"
                                      height="21px"
                                      alt=""
                                    /> */}
                                  </div>
                                  <div style={{ lineHeight: 1 }}>
                                    {/* <Link
                                      to={"/view-battel/" + data?._id}
                                    
                                      className="btn btn-success btn-xs btn-req"
                                    >
                                      start
                                    </Link> */}
                                    <a
                                      href="#"
                                      onClick={() => {
                                        hendletoStart(
                                          data?._id,
                                          profile?.users?._id ==
                                            data?.owner?._id
                                            ? "o"
                                            : "i"
                                        );
                                      }}
                                      className="btn btn-success btn-xs btn-req"
                                    >
                                      start
                                    </a>
                                    {/* <span className="">
                                      Waiting for room code
                                    </span> */}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <style>
              .btn-req {
                margin-left: 60%;
                margin-top: 1px;
                position: absolute;
                padding: 4px;
                font-size: 9px;
                color: white;
                background-color: grey;
                font-weight: 800;
              }
              .btn-can {
                margin-left: 80%;
                margin-top: 1px;
                padding: 4px;
                position: absolute;
                font-size: 9px;
                color: white;
                font-weight: 800;
              }
              @media screen and (min-width: 393px) {
                .btn-req {
                  margin-left: 68%;
                  margin-top: 1px;
                  position: absolute;
                  padding: 4px;
                  font-size: 9px;
                  color: white;
                  background-color: grey;
                  font-weight: 800;
                }
                .btn-can {
                  margin-left: 83%;
                  margin-top: 1px;
                  padding: 4px;
                  position: absolute;
                  font-size: 9px;
                  color: white;
                  font-weight: 800;
                }
              }
            </style> */}
              </div>
            </div>
            {/* <!---------------------------
    -----------------------------
    Code For OPEN BATTLE END
    -----------------------------
    ----------------------------> */}

            <div className="divider-x"></div>

            {/* <!---------------------------
    -----------------------------
    Code For Running Battle start
    -----------------------------
    ----------------------------> */}
            <div className="px-4 py-3">
              <div className="mb-2">
                <img
                  src="../assest/image/global-battleIconWhiteStroke.png"
                  width="20px"
                  alt=""
                />
                <span className="ms-2 games-section-title">
                  {t("running")} Battles{" "}
                </span>
              </div>

              <div id="myRunningBattles"></div>

              <div id="runningBattle">
                {/* <!-- battle running--> */}

                {battel_List?.running &&
                  battel_List?.running?.map((data, i) => {
                    const yourBets =
                      profile?.users?._id?.match(data?.owner?._id) ||
                      profile?.users?._id?.match(data?.playerone?._id)
                        ? true
                        : false;
                    return (
                      <div
                        id="633c3b4e85a962efab2f95db"
                        className="betCard mt-1"
                      >
                        <div className="d-flex">
                          <span className="betCard-title pl-3 d-flex align-items-center text-uppercase">
                            PLAYING FOR
                            <img
                              className="mx-1"
                              src="../assest/image/global-rupeeIcon.png"
                              width="21px"
                              alt=""
                            />
                            {data?.amount}
                          </span>

                          <div className="d-flex align-items-center text-uppercase">
                            <span className="ml-auto mr-3">
                              {yourBets && (
                                <a
                                  // href="#"
                                  onClick={() => {
                                    navigate("/view-battel/" + data?._id);
                                  }}
                                  className="btn btn-info btn-sm"
                                  style={{ padding: "3px", fontSize: "11px" }}
                                >
                                  {/* View */}
                                  {t("view")}
                                </a>
                              )}
                            </span>
                          </div>
                          <div className="betCard-title d-flex align-items-center text-uppercase">
                            <span className="ml-auto mr-3">
                              PRIZE
                              <img
                                className="mx-1"
                                src="../assest/image/global-rupeeIcon.png"
                                width="21px"
                                alt=""
                              />
                              {data?.prize}
                            </span>
                          </div>
                        </div>

                        <div className="py-1 row">
                          <div className="pr-3 text-center col-5">
                            <div className="pl-2">
                              <img
                                className="border-50"
                                src="../assest/image/Avatar2.png"
                                width="21px"
                                height="21px"
                                alt=""
                              />
                            </div>
                            <div style={{ lineHeight: 1 }}>
                              <span className="betCard-playerName">
                                {data?.owner?.uid}
                              </span>
                            </div>
                          </div>
                          <div className="pr-3 text-center col-2 cxy">
                            <div>
                              <img
                                src="../assest/image/vs.png"
                                width="21px"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="text-center col-5">
                            <div className="pl-2">
                              <img
                                className="border-50"
                                src="../assest/image/Avatar2.png"
                                width="21px"
                                height="21px"
                                alt=""
                              />
                            </div>
                            <div style={{ lineHeight: 1 }}>
                              <span className="betCard-playerName">
                                {data?.playerone?.uid}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {battel_List_2 &&
                  battel_List_2?.map((data, i) => {
                    const yourBets =
                      profile?.users?._id?.match(data?.owner?._id) ||
                      profile?.users?._id?.match(data?.playerone?._id)
                        ? true
                        : false;

                    return (
                      <div
                        id="633c3b4e85a962efab2f95db"
                        className="betCard mt-1"
                      >
                        <div className="d-flex">
                          <span className="betCard-title pl-3 d-flex align-items-center text-uppercase">
                            PLAYING FOR
                            <img
                              className="mx-1"
                              src="../assest/image/global-rupeeIcon.png"
                              width="21px"
                              alt=""
                            />
                            {data?.amount}
                          </span>

                          <div className="d-flex align-items-center text-uppercase">
                            <span className="ml-auto mr-3">
                              {/* {yourBets && (
                                <a
                                  // href="#"
                                  onClick={() => {
                                    navigate("/view-battel/" + data?._id);
                                  }}
                                  className="btn btn-info btn-sm"
                                  style={{ padding: "3px", fontSize: "11px" }}
                                >
                                  View
                                  {t("view")}
                                </a>
                              )} */}
                            </span>
                          </div>
                          <div className="betCard-title d-flex align-items-center text-uppercase">
                            <span className="ml-auto mr-3">
                              PRIZE
                              <img
                                className="mx-1"
                                src="../assest/image/global-rupeeIcon.png"
                                width="21px"
                                alt=""
                              />
                              {data?.prize}
                            </span>
                          </div>
                        </div>

                        <div className="py-1 row">
                          <div className="pr-3 text-center col-5">
                            <div className="pl-2">
                              <img
                                className="border-50"
                                src="../assest/image/Avatar2.png"
                                width="21px"
                                height="21px"
                                alt=""
                              />
                            </div>
                            <div style={{ lineHeight: 1 }}>
                              <span className="betCard-playerName">
                                {data?.user}
                              </span>
                            </div>
                          </div>
                          <div className="pr-3 text-center col-2 cxy">
                            <div>
                              <img
                                src="../assest/image/vs.png"
                                width="21px"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="text-center col-5">
                            <div className="pl-2">
                              <img
                                className="border-50"
                                src="../assest/image/Avatar2.png"
                                width="21px"
                                height="21px"
                                alt=""
                              />
                            </div>
                            <div style={{ lineHeight: 1 }}>
                              <span className="betCard-playerName">
                                {data?.user2}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Onbid2;
