import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { allapiAction } from "../Redux/common/action";
import { SIDERBAR_BOS } from "../Redux/common/constant";

function Header() {
  const [first, setfirst] = useState(false);

  const dispatch = useDispatch();
  const otpBoxAction = (data) => {
    return { type: SIDERBAR_BOS, payload: data };
  };

  // profile

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  useEffect(() => {
    dispatch(allapiAction.getprofile({}));
    return () => { };
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(allapiAction.getprofile({}));
      return () => { };
    }, 5000);

    // const interval = setInterval(() => {
    //   setCount(count => count + 1);
    // }, 1000);

    return () => clearInterval(interval);
  }, []);

  const hendleSideBar = (e) => {
    dispatch(otpBoxAction(e));
  };
  const handleClick = (e) => {
    console.log(e?.target?.value);
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18next.changeLanguage(lang);
    }
  };
  useEffect(() => {
    handleClick();
  }, []);

  return (
    <div>
      <div className="headerContainer">
        <picture className="sideNavIcon ml-3 mr-2">
          <img
            src="../assest/image/header-hamburger.png"
            onClick={() => {
              hendleSideBar(true);
            }}
            alt=""
          />
        </picture>

        <Link to={"/"}>
          <picture className="ml-2 navLogo d-flex">
            <img src="../assest/image/logo.png" alt="" />
          </picture>
        </Link>
        {/* <a href="/public">
          <picture className="ml-2 navLogo d-flex">
            <img
              src="../assest/image/Header_profile.jpeg"
              alt=""
            />
          </picture>
        </a> */}
        <div className="menu-items">
          <div id="walletBalance">
            <div className="menu-items">
              {/* add-funds */}
              <Link to={"/add-funds"} className="box">
                <picture className="moneyIcon-container">
                  <img src="../assest/image/global-rupeeIcon.png" alt="" />
                </picture>
                <div className="mt-1 ml-1">
                  <div className="moneyBox-header">Cash</div>
                  <div className="moneyBox-text">
                    ₹{profile?.wallets?.closeBal.toFixed(2)}
                  </div>
                </div>
                <picture className="moneyBox-add">
                  <img src="../assest/image/global-addSign.png" alt="" />
                </picture>
              </Link>
              {/* <a
                className="box"
                href="#"
              >
                <picture className="moneyIcon-container">
                  <img
                    src="../assest/image/global-rupeeIcon.png"
                    alt=""
                  />
                </picture>
                <div className="mt-1 ml-1">
                  <div className="moneyBox-header">Cash</div>
                  <div className="moneyBox-text">
                    ₹{profile?.wallets?.closeBal}
                  </div>
                </div>
                <picture className="moneyBox-add">
                  <img
                    src="../assest/image/global-addSign.png"
                    alt=""
                  />
                </picture>
              </a> */}
              &nbsp;
              <Link className="box" to="/withdrawal-request">
                <picture className="moneyIcon-container">
                  <img src="../assest/image/reward_red.png" alt="" />
                </picture>
                <div className="mt-1 ml-1">
                  <div className="moneyBox-header">Winning</div>
                  <div className="moneyBox-text">
                    ₹{profile?.wallets?.winningBal}
                  </div>
                </div>
                <picture className="moneyBox-add"></picture>
              </Link>
            </div>
            <span className="mx-5"> </span>
          </div>
        </div>

        <span className="mx-5"></span>
      </div>
    </div>
  );
}

export default Header;
