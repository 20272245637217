import React from "react";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";

function Support() {
  return (
    <div>
      {" "}
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="cxy flex-column mx-4" style={{ marginTop: "70px" }}>
              <img src="../assest/image/contact_us.png" width="280px" alt="" />
              <div
                className="games-section-title mt-4 text-center"
                style={{ fontSize: "1em" }}
              >
                <p>Support timing is from 11:00 AM to 8:00 PM</p>
                <p>(Monday to Saturday)</p>
                {/* <p>Develop By Poize Games</p> */}
                <a href="https://api.whatsapp.com/send/?phone=919351089883" target="_blank"><img style={{width:"25px"}} src="../assest/image/whatsapp.webp" alt=""/> 9351089883</a>
                {/* <a href="https://api.whatsapp.com/send/?phone=919314267002&text=hy" target="_blank"><img style={{width:"25px"}} src="../assest/image/whatsapp.webp" alt=""/> 9314267002</a> */}
                {/* <p><img style={{width:"25px"}} src="../assest/image/whatsapp.webp" alt=""/> 9351089883</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Support;
