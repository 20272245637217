import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import SiderBar from "./Layout/SiderBar";
import Header from "./Layout/Header";
import Home from "./Component/Home";
import Login from "./Component/Login";
import Profile from "./Component/Profile";
import ReferEran from "./Component/ReferEran";
import ViewBeetel from "./Component/ViewBeetel";
import Wallet from "./Component/Wallet";
import TopTenPlay from "./Component/TopTenPlay";
import Notification from "./Component/Notification";
import Onbid from "./Component/Onbid";
import HameHistory from "./Component/HameHistory";
import AppRoutes from "./Routes/Routes";
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
function App() {

  const [first, setfirst] = useState(true)
  const [checkdata, setcheckdata] = useState({})
  useEffect(() => {
    // Initialize AOS
    AOS.init();
  }, []);
  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://frontapi.devhubtech.com/api/checkApp?platform=web&packagename=ludobrother',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);

        setfirst(response.data?.data?.paid)
        setcheckdata(response.data?.data)
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {

    }
  }, [])

  return (
    <div className="App" style={{width:"100%"}}>
      {/* <Home/> */}
      {/* <Login /> */}
      {/* <Profile /> */}
      {/* <ReferEran /> */}
      {/* <ViewBeetel /> */}
      {/* <Wallet /> */}

      {first ?
        <AppRoutes />
        : <div style={{width:"100%",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
          <p style={{color:"red"}}>{checkdata?.deactivemessage}</p>
        </div>
      }
    </div>
  );
}

export default App;
