import React from "react";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";

function Notification() {
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="d-flex px-4 py-3 list-item">
              <picture className="mr-3">
                <img
                  height="36px"
                  width="36px"
                  src="../assest/image/kyc-icon-new.png"
                  alt=""
                />
              </picture>
              <div>
                <span className="font-9 fw-800 d-flex">
                  Your Kyc is REJECTED
                </span>
                <small className="d-flex font-7 my-2">
                  Reason: Please upload clear photo of your document.
                </small>
                <small
                  className="d-flex font-7"
                  style={{ color: "rgb(199, 199, 199)" }}
                >
                  7 Oct 11:10 AM
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Notification;
